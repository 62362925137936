.hide {
  display: none;
}

/*//Border Radius
@mixin border-radius($border-radius){
    -moz-border-radius: $border-radius;
    -webkit-border-radius: $border-radius;
    -khtml-border-radius: $border-radius;
    border-radius: $border-radius;
}*/
/*@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: calculateRem($size);
} */
/*@mixin placeholder {
    &.placeholder { @content }
    &:-moz-placeholder { @content }
    &::-moz-placeholder{@content}
    &:-ms-input-placeholder{@content}
    &::-webkit-input-placeholder { @content }
}*/
/*@mixin size($width, $height: $width){ 
    width: $width;
    height: $height;  
}*/
/*
//Keyframes auto smooth animated arrow
@include keyframes(bounce) {
	0%, 20%, 50%, 80%, 100% {
    @include transform(translateY(0));
  }
	40% {
    @include transform(translateY(-30px));
  }
	60% {
    @include transform(translateY(-15px));
  }
}*/
.carousel__button {
  display: none;
}

.shop-category {
  padding: 15px 0;
  border-bottom: 1px solid #e5e5e5;
}
.shop-category .top-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 30px;
}
.shop-category .top-list a {
  margin: 0 2px 25px;
  text-align: center;
  width: 16%;
  color: #333333;
  font-family: "pangramregular";
  font-size: 0.9375rem;
  line-height: 20px;
}
.shop-category .top-list a:hover {
  color: #e57200;
}
.shop-category .top-list figure {
  margin-bottom: 10px;
  background: #fff !important;
}
.shop-category .top-list figure:before {
  padding-top: 86% !important;
}

.main-banner {
  min-height: 385px;
}
.main-banner a {
  display: block;
}
.main-banner a figure:before {
  padding-top: 31% !important;
}
.main-banner a figure img {
  border-radius: 3px;
}

.Top-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.Top-section figure::before {
  padding-top: 49% !important;
}
.Top-section figure img {
  opacity: 1 !important;
  -webkit-transition: none !important;
  transition: none !important;
}
.Top-section .decor-slider {
  width: 73%;
  margin: 0 auto;
  margin-right: 10px;
}
.Top-section .decor-slider .slick-track {
  overflow: hidden;
}
.Top-section .decor-slider a {
  display: inline-block;
}
.Top-section .other-banner {
  width: 26%;
}
.Top-section .other-banner a {
  display: block;
}
.Top-section .other-banner figure::before {
  padding-top: 137% !important;
}
.Top-section .slick-next,
.Top-section .slick-prev {
  background: rgba(255, 255, 255, 0.5882352941);
  width: 32px;
  height: 32px;
}
.Top-section .slick-next::before,
.Top-section .slick-prev::before {
  font-size: 15px;
  color: #333333;
}
.Top-section .slick-next {
  right: 0px;
}
.Top-section .slick-prev {
  left: 0px;
  z-index: 1;
}

.top-list img,
.bannerslider img,
.offer-banner img,
.banner-mid img {
  width: auto;
  height: auto;
}

.figure-overlay, .category-products .product-list .single-product figure, .shopSlider figure,
.blogSlider figure,
.bannerslider figure, .decor-section .decor-wrpr .decor-list figure, .shop-category .top-list figure, .main-banner a figure, .Top-section figure {
  position: relative;
  overflow: hidden;
  background: #f5f5f5;
}
.figure-overlay img, .category-products .product-list .single-product figure img, .shopSlider figure img,
.blogSlider figure img,
.bannerslider figure img, .decor-section .decor-wrpr .decor-list figure img, .shop-category .top-list figure img, .main-banner a figure img, .Top-section figure img {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: auto;
  height: auto;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.figure-overlay:before, .category-products .product-list .single-product figure:before, .shopSlider figure:before,
.blogSlider figure:before,
.bannerslider figure:before, .decor-section .decor-wrpr .decor-list figure:before, .shop-category .top-list figure:before, .main-banner a figure:before, .Top-section figure:before {
  padding-top: 100%;
  content: "";
  display: block;
}

.bg-gray {
  background: #f5f5f5;
}

.categoryAll {
  background: #f8f8f8;
  padding: 20px 0;
  margin-bottom: 30px;
}
.categoryAll .title-name {
  text-transform: uppercase;
  color: #242424;
  margin-bottom: 5px;
  display: block;
}
.categoryAll .title-name a {
  font-size: 15px;
  font-family: "pangrammedium";
  letter-spacing: 0.6px;
}
.categoryAll a {
  display: block;
  font-family: "pangramregular";
  line-height: 20px;
  letter-spacing: 0.4px;
  font-size: 13.5px;
  color: #242424;
  padding: 3px 0;
}
.categoryAll figure {
  position: relative;
  overflow: hidden;
}
.categoryAll figure a {
  display: block;
}
.categoryAll figure img {
  position: absolute;
  mix-blend-mode: darken;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
}
.categoryAll figure:hover img {
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
}
.categoryAll figure:before {
  content: "";
  display: block;
  padding-top: 52%;
}

.flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.flexbox article {
  width: 19.4%;
  background: #fff;
  margin-right: 1%;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.flexbox article:last-child {
  margin-right: 0px;
}
.flexbox .in {
  padding: 15px 10px;
}
.flexbox .in a:hover {
  color: #E57200;
}

.decorHeading {
  font-family: "pangrammedium";
  text-align: center;
  color: #333333;
  font-size: 1.625rem;
  line-height: 28px;
}
.decorHeading p {
  font-size: 0.875rem;
  line-height: 20px;
  font-family: "pangramregular";
  color: #515151;
  letter-spacing: 0.02em;
  margin-top: 4px;
}
.decorHeading h2 {
  margin: 0;
  font-size: 1.625rem;
}
.decorHeading .head-title {
  font-size: 1.625rem;
  margin: 0px;
}

.top-content {
  margin-top: 50px;
}

.decor-toggle {
  height: 20px;
  overflow: hidden;
  width: 650px;
  margin: 0px auto;
  position: relative;
  line-height: normal;
}
.decor-toggle b,
.decor-toggle strong {
  font-size: 0.9375rem;
  font-family: "pangrammedium";
  color: #333333;
  font-weight: normal;
}
.decor-toggle a {
  color: #E27A34;
}
.decor-toggle.active {
  height: auto;
  width: auto;
  padding: 0px 10px;
  white-space: normal;
}
.decor-toggle.active .more {
  position: inherit;
}
.decor-toggle.active .more:before {
  content: "Less";
}

.decor-section {
  padding: 40px 0;
}
.decor-section .decorHeading {
  margin-bottom: 20px;
}
.decor-section .decor-wrpr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.decor-section .decor-wrpr .decor-list {
  width: 19%;
  background: #fff;
  padding: 4px;
  margin: 0 auto 20px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.decor-section .decor-wrpr .decor-list:hover {
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: -3px;
}
.decor-section .decor-wrpr .decor-list figure:before {
  padding-top: 86%;
}
.decor-section .decor-wrpr .name {
  padding: 8px;
  font-size: 0.9375rem;
  line-height: 20px;
  color: #333333;
  letter-spacing: 0.05em;
  font-family: "pangrammedium";
  text-align: center;
}
.decor-section .decor-wrpr .name span {
  display: block;
  font-size: 0.8125rem;
  line-height: 20px;
  font-family: "pangramregular";
  color: #515151;
  letter-spacing: 0;
}

.shopSlider,
.blogSlider,
.bannerslider {
  margin: 20px auto;
  font-family: "pangramregular";
}
.shopSlider .slick-track,
.blogSlider .slick-track,
.bannerslider .slick-track {
  margin: 0 auto;
}
.shopSlider a,
.blogSlider a,
.bannerslider a {
  color: #333333;
  text-align: center;
  display: block;
  margin: 0 8px;
  letter-spacing: 0.02rem;
}
.shopSlider a:hover .name,
.blogSlider a:hover .name,
.bannerslider a:hover .name {
  color: #e57200;
}
.shopSlider .name,
.blogSlider .name,
.bannerslider .name {
  font-size: 0.9375rem;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 3px;
  font-family: "pangrammedium";
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.shopSlider figure,
.blogSlider figure,
.bannerslider figure {
  border-radius: 3px;
}
.shopSlider figure::before,
.blogSlider figure::before,
.bannerslider figure::before {
  padding-top: 86%;
}
.shopSlider figure:hover img,
.blogSlider figure:hover img,
.bannerslider figure:hover img {
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
}
.shopSlider .slick-next,
.shopSlider .slick-prev,
.blogSlider .slick-next,
.blogSlider .slick-prev,
.bannerslider .slick-next,
.bannerslider .slick-prev {
  top: 42%;
  background: #fff;
  width: 30px;
  height: 32px;
}
.shopSlider .slick-next::before,
.shopSlider .slick-prev::before,
.blogSlider .slick-next::before,
.blogSlider .slick-prev::before,
.bannerslider .slick-next::before,
.bannerslider .slick-prev::before {
  font-size: 15px;
  color: #333333;
}
.shopSlider .slick-next,
.blogSlider .slick-next,
.bannerslider .slick-next {
  right: 0px;
}
.shopSlider .slick-prev,
.blogSlider .slick-prev,
.bannerslider .slick-prev {
  left: 8px;
  z-index: 1;
}

.bannerslider figure::before {
  padding-top: 150% !important;
}

.blogSlider figure {
  background-color: #f3f3f3;
}
.blogSlider figure::before {
  padding-top: 60%;
}
.blogSlider .name {
  white-space: normal;
}

.more {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  cursor: pointer;
  color: #e57200;
  font-family: "pangramregular";
  padding-left: 5px;
}
.more:before {
  content: "Read More";
  display: inline-block;
}

.offer-banner {
  display: block;
  margin: 20px auto;
}

.newarrivals {
  padding-bottom: 20px;
  padding-top: 30px;
  margin-top: 20px;
}
.newarrivals.top-selling .shopSlider p {
  text-align: left;
  font-family: "pangrammedium";
}
.newarrivals .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.newarrivals .flex .decorHeading {
  padding: 0;
  text-align: left;
}
.newarrivals .view-more {
  color: #515151;
  border: 1px solid #515151;
  border-radius: 3px;
  padding: 8px 12px;
  font-family: "pangramregular";
}
.newarrivals .view-more svg {
  vertical-align: sub;
}
.newarrivals .view-more:hover {
  color: #E27A34;
  border: 1px solid #E27A34;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.newarrivals .view-more:hover svg path {
  stroke: #E27A34;
}
.newarrivals .discount-off {
  font-size: 0.875rem;
  line-height: 18px;
  margin-left: 5px;
  color: #4CAF4F;
}

.DecorBestsellers {
  padding: 30px 0;
}
.DecorBestsellers a {
  text-align: left;
}
.DecorBestsellers a .product-name {
  font-size: 0.9375rem;
  line-height: 20px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333333;
  margin: 5px auto 2px;
}
.DecorBestsellers a .off-price {
  margin: 0 5px;
  font-size: 14px;
  font-family: "pangramregular";
  color: #646464;
}
.DecorBestsellers a .price {
  font-family: "pangrammedium";
  color: #333333;
}
.DecorBestsellers a:hover .product-name {
  color: #e57200;
}
.DecorBestsellers .four-slider figure:before {
  padding-top: 100% !important;
}

.brand-section .brand-img {
  margin: 10px auto;
  width: auto;
  height: auto;
}
.brand-section .name {
  white-space: normal;
}
.brand-section a:hover .name {
  color: #515151;
}

.brand-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 15px;
}
.brand-flex a {
  width: 15%;
  display: inline-block;
  margin: 0 5px 20px;
}
.brand-flex a:hover img {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.buying-guide .flex-banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.buying-guide .decorHeading svg {
  vertical-align: middle;
}
.buying-guide .iframe-container {
  position: relative;
  padding-top: 58%;
}
.buying-guide .guide-link {
  color: #E27A34;
  font-family: "pangramregular";
  font-size: 17px;
  line-height: 24px;
  padding: 10px 0 0;
  display: block;
  text-decoration: underline;
}
.buying-guide iframe {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.buying-guide .guide-block {
  display: block;
  margin: 0 10px;
  width: 46%;
  text-align: center;
}

.category-products {
  padding: 30px 0;
}
.category-products .tabs-nav {
  padding: 20px 0 15px;
  overflow: auto;
  white-space: nowrap;
  margin-bottom: 5px;
  text-align: center;
}
.category-products .tabs-nav::-webkit-scrollbar-thumb {
  background: #acacac;
}
.category-products .tabs-nav::-webkit-scrollbar-track {
  background: #646464;
}
.category-products .tabs-nav::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.category-products .tabs-nav li {
  display: inline-block;
}
.category-products .tabs-nav li.active a {
  color: #fff;
  background: #E27A34;
  border: 1px solid #E27A34;
}
.category-products .tabs-nav li.active a:hover {
  background: #E27A34;
}
.category-products .tabs-nav li a {
  font-size: 0.9375rem;
  line-height: 20px;
  color: #515151;
  font-family: "pangrammedium";
  letter-spacing: 0.02em;
  border: 1px solid #8B8B8B;
  display: block;
  margin-right: 10px;
  padding: 7px 10px;
  border-radius: 50px;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.category-products .tabs-nav li a:hover {
  background: #f5f5f5;
}
.category-products .product-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.category-products .product-list .single-product {
  width: 25%;
  margin-bottom: 30px;
}
.category-products .product-list .single-product figure {
  border-radius: 3px;
}
.category-products .product-list .single-product figure:before {
  padding-top: 86%;
}
.category-products .product-list .single-product a {
  color: #333333;
  display: block;
  margin: 0 8px;
  letter-spacing: 0.02rem;
  font-family: "pangramregular";
}
.category-products .product-list .single-product .name {
  font-size: 0.9375rem;
  line-height: 20px;
  margin: 5px 0 3px;
  font-family: "pangrammedium";
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.category-products .product-list .single-product .price {
  font-family: "pangrammedium";
  font-size: 0.9375rem;
}
.category-products .product-list .single-product .price .off-price {
  color: #646464;
  font-family: "pangramregular";
  margin: 0 3px;
  font-size: 0.875rem;
}
.category-products .product-list .single-product .price .discount-off {
  color: #4CAF4F;
  font-size: 0.875rem;
}
.category-products .explore-btn {
  margin: 20px auto;
  text-align: center;
}
.category-products .explore-btn .more-btn {
  background: #333333;
  color: #fff;
  padding: 10px 15px;
  display: inline-block;
  font-size: 1rem;
  line-height: 20px;
  font-family: "pangramregular";
  letter-spacing: 0.02em;
  border-radius: 3px;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.category-products .explore-btn .more-btn:hover {
  background: #515151;
}

@media screen and (max-width: 1366px) {
  .Top-section {
    height: 469px;
    min-height: 469px;
  }
}
@media screen and (max-width: 1280px) {
  .Top-section {
    height: 440px;
    min-height: 440px;
  }
  .Top-section .decore-slider {
    width: 73%;
    margin-right: 13px;
  }
  .main-banner {
    min-height: 365px;
  }
  .container-1200 {
    max-width: 1180px;
  }
}
@media screen and (max-width: 1024px) {
  .Top-section {
    min-height: 350px;
    height: 350px;
  }
  .Top-section .decor-slider {
    width: 72%;
  }
  .Top-section .other-banner figure::before {
    padding-top: 136% !important;
  }
  .main-banner {
    min-height: 300px;
  }
  .decorHeading {
    font-size: 1.25rem;
    line-height: 24px;
    padding: 20px 0;
  }
  .decorHeading .head-title {
    font-size: 1.25rem;
    line-height: 24px;
  }
  .top-content {
    margin-top: 0px;
  }
  .decor-section {
    padding: 20px 0;
  }
  .decor-section .decor-wrpr .decor-list {
    width: 23%;
  }
  .newarrivals {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .slick-dots li button:before {
    background: #acacac;
    border-radius: 50%;
  }
  .slick-dots {
    text-align: center;
    margin-top: 25px;
  }
  .slick-dots .slick-active button::before {
    background: #E27A34;
  }
  .shopSlider .name {
    margin-top: 5px;
    margin-bottom: 2px;
  }
  .shop-category .top-list a {
    width: 13%;
  }
  .shop-category .top-list figure {
    margin: 0 0 5px;
  }
  .shopSlider,
  .blogSlider,
  .bannerslider {
    margin: 10px auto;
  }
}
@media screen and (max-width: 768px) {
  .decorHeading {
    padding: 10px 0;
  }
  .main-banner {
    min-height: 230px;
  }
  .shop-category {
    padding-bottom: 0;
  }
  .shop-category .top-list a {
    margin: 0 auto 20px;
    width: 22%;
  }
  .decor-toggle {
    width: 600px;
  }
  .shopSlider {
    margin: 15px auto;
  }
  .shopSlider a {
    margin: 0 5px;
  }
  .decor-section .decor-wrpr .name {
    font-size: 0.875rem;
    line-height: 18px;
    letter-spacing: 0.02em;
    padding: 5px;
  }
  .newarrivals {
    margin-top: 20px;
  }
}
@media only screen and (max-width:767px) {
  .breadcrumbs {
    padding: 0 10px;
  }
  .breadcrumbs li a {
    padding: 10px 0;
  }
  .figure, .category-products .product-list .single-product figure, .main-slider a figure, .main-slider.new-height a figure, .shop-category .top-category figure, .newslider a figure {
    position: relative;
    overflow: hidden;
  }
  .figure::before, .category-products .product-list .single-product figure::before, .main-slider a figure::before, .main-slider.new-height a figure::before, .shop-category .top-category figure::before, .newslider a figure::before {
    content: "";
    padding-top: 100%;
    display: block;
  }
  .figure img, .category-products .product-list .single-product figure img, .main-slider a figure img, .main-slider.new-height a figure img, .shop-category .top-category figure img, .newslider a figure img {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: auto;
    height: auto;
  }
  .gray-bg {
    background: #fff;
  }
  .hdfc-banner {
    margin: 15px auto 5px;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 5px;
  }
  .hdfc-banner img {
    height: auto;
    width: auto;
  }
  .decor-toggle {
    width: auto;
    height: 18px;
    text-align: justify;
    letter-spacing: 0;
  }
  .decor-toggle.active {
    padding: 0;
  }
  .decorHeading {
    padding: 0px;
  }
  .decorHeading h2 {
    font-size: 1.1875rem;
  }
  .decorHeading p {
    font-size: 0.8125rem;
    line-height: 18px;
    margin: 2px auto 0;
  }
  .top-section.decorHeading {
    padding: 15px 10px 0;
    margin-top: 10px;
  }
  .offer-banner {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .bank-offer {
    padding: 10px 0;
    margin: 10px auto;
  }
  .top-icons {
    padding-bottom: 0;
  }
  .top-icons .ws-scroll {
    white-space: normal !important;
    margin: 0;
  }
  .top-icons .top-category a {
    width: 30% !important;
    margin: 0px 5px 20px !important;
    color: #333333 !important;
    font-size: 13px !important;
  }
  .top-icons .top-category a figure {
    margin-bottom: 5px;
  }
  .top-icons .top-category a figure:before {
    padding-top: 87%;
  }
  .newslider {
    min-height: 255px;
  }
  .newslider a {
    display: block;
  }
  .newslider a figure:before {
    padding-top: 71%;
  }
  .shopSlider {
    margin: 0;
    padding: 10px 0px 0;
  }
  .shopSlider a {
    width: 45%;
    display: inline-table;
    margin-bottom: 15px;
    margin-right: 10px;
    margin-left: 0;
    font-size: 0.75rem;
  }
  .shopSlider a span {
    font-size: 14px;
    color: #333;
    font-family: "pangramregular";
    margin: 5px auto;
    display: block;
    margin: 5px auto;
  }
  .shopSlider a .name {
    font-size: 0.8125rem;
    line-height: 18px;
  }
  .shopSlider a:hover .name {
    color: #E27A34;
  }
  .shopSlider a:hover img {
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
  }
  .shopSlider::-webkit-scrollbar {
    display: none;
  }
  .border-bottom {
    border-bottom: 5px solid #f5f5f5;
  }
  .blogsection {
    border-bottom: 10px solid #f5f5f5;
    border-top: 2px solid #f5f5f5;
    margin-bottom: 20px;
    margin: 0;
  }
  .blogsection .blogSlider {
    margin-bottom: 0;
  }
  .blogsection .blogSlider a {
    margin: 0 10px 0 0px;
    width: 75%;
    display: inline-table !important;
  }
  .blogsection .blogSlider a:hover .name {
    color: #E27A34;
  }
  .blogsection .blogSlider .name {
    font-size: 0.8125rem;
    line-height: 18px;
    text-align: left;
    font-family: "pangramregular";
  }
  .newarrivals {
    margin-top: 10px;
  }
  .newarrivals .shopSlider,
  .newarrivals .blogSlider {
    display: block;
    white-space: nowrap;
    overflow: auto;
    margin-right: -10px;
  }
  .newarrivals .shopSlider::-webkit-scrollbar,
  .newarrivals .blogSlider::-webkit-scrollbar {
    display: none;
  }
  .newarrivals .shopSlider a,
  .newarrivals .blogSlider a {
    display: inline-block;
    text-align: left;
  }
  .newarrivals .shopSlider .price,
  .newarrivals .blogSlider .price {
    font-size: 13px;
    letter-spacing: 0;
    color: #333333;
  }
  .newarrivals .shopSlider .price .off-price,
  .newarrivals .blogSlider .price .off-price {
    color: #646464;
    font-size: 12px;
  }
  .newarrivals.top-selling .name {
    font-family: "pangramregular" !important;
    letter-spacing: 0;
  }
  .newarrivals.top-selling .price {
    font-size: 12px;
    font-family: "pangramregular";
  }
  .newarrivals.top-selling .price .off-price {
    margin: 0 3px 0 0;
    font-family: "pangramregular" !important;
  }
  .newarrivals .discount-off {
    display: inline-block;
    font-size: 12px;
    margin: 0;
    letter-spacing: 0;
  }
  .newarrivals .view-more {
    text-align: center;
    display: block;
    margin: 10px auto 15px;
    width: 100%;
    max-width: 125px;
  }
  .DecorBestsellers {
    padding: 15px 0;
    border-bottom: 10px solid #f5f5f5;
  }
  .DecorBestsellers .decorHeading {
    margin-bottom: 10px;
  }
  .DecorBestsellers .shopSlider {
    white-space: nowrap;
    overflow: auto;
    padding: 0 10px;
  }
  .DecorBestsellers a {
    display: inline-block;
  }
  .DecorBestsellers a .price,
  .DecorBestsellers a .product-name {
    font-size: 0.875rem;
  }
  .DecorBestsellers a .price {
    font-family: "pangramregular";
    font-size: 0.75rem;
    color: #646464;
  }
  .DecorBestsellers a .product-name {
    margin: 5px auto;
  }
  .decor-section .decorHeading {
    margin-bottom: 0;
  }
  .decor-section .decor-wrpr {
    display: block;
    white-space: nowrap;
    overflow: auto;
    margin-top: 10px;
    margin-right: -10px;
  }
  .decor-section .decor-wrpr::-webkit-scrollbar {
    display: none;
  }
  .decor-section .decor-wrpr .decor-list {
    width: 45%;
    display: inline-block;
    white-space: normal;
    padding: 0;
    margin-right: 10px;
    margin-bottom: 15px;
  }
  .decor-section .decor-wrpr .decor-list:hover {
    margin-bottom: 0;
  }
  .decor-section .decor-wrpr .decor-list:hover .name {
    color: #E27A34;
  }
  .decor-section .decor-wrpr .name {
    font-size: 0.8125rem;
  }
  .decor-section .decor-wrpr .name span {
    font-size: 0.75rem;
  }
  .decor-section .cat_btn.m-menu {
    text-align: center;
    width: 70%;
    display: block;
  }
  .shop-category {
    padding: 15px 10px 0px !important;
    text-align: center;
  }
  .shop-category .ws-scroll {
    display: block;
    white-space: nowrap;
    overflow: auto;
    margin-right: -10px;
  }
  .shop-category .ws-scroll::-webkit-scrollbar {
    display: none;
  }
  .shop-category .top-category {
    display: block;
  }
  .shop-category .top-category a {
    font-size: 0.75rem;
    line-height: 17px;
    margin-bottom: 20px;
    color: #646464;
    margin-right: 10px;
    display: inline-table;
    white-space: normal;
    font-family: "pangramregular";
    width: 28%;
    letter-spacing: 0.02em;
  }
  .shop-category .top-category a:hover {
    color: #E27A34;
  }
  .shop-category .top-category figure img {
    height: auto;
    width: auto;
  }
  .brand-section {
    margin: 20px auto;
    padding: 20px 0 10px;
    text-align: center;
  }
  .brand-section .shopSlider1 {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 6px 1fr 6px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 6px;
    grid-column-gap: 6px;
    padding-top: 10px;
  }
  .brand-section .shopSlider1 a {
    width: auto;
    display: -ms-inline-grid;
    display: inline-grid;
    -webkit-box-shadow: 0 2px 3px #d7d7d7;
            box-shadow: 0 2px 3px #d7d7d7;
  }
  .brand-section .shopSlider1 a img {
    border-radius: 3px;
  }
  .brand-section .brand-img {
    display: block;
    margin: 0 auto 5px;
    height: 50px;
  }
  .brand-section .shopSlider a {
    width: 31%;
    margin: 0 3px 15px;
  }
  .brand-section .name {
    font-size: 14px;
    white-space: normal;
    text-align: center;
    color: #707070;
    font-family: "pangramregular";
  }
  .banner-slider {
    border-top: 10px solid #f5f5f5;
    padding: 20px 10px;
  }
  .banner-slider .shopSlider a {
    width: 80%;
    margin-bottom: 0;
  }
  .banner-slider .shopSlider a figure::before {
    padding-top: 150%;
  }
  .banner-slider .shopSlider a figure img {
    width: auto;
    height: auto;
  }
  .cat_btn.m-menu {
    margin: 15px auto;
    padding: 10px 30px;
    font-size: 13px;
    line-height: 20px;
    color: #515151;
    border: 1px solid #8b8b8b;
    border-radius: 4px;
    font-family: "pangramregular";
    display: inline-block;
  }
  .cat_btn.m-menu svg {
    margin-right: 5px;
    vertical-align: middle;
  }
  .testimonials {
    font-family: "pangramregular";
  }
  .testimonials p {
    font-family: "pangramregular";
    color: #646464;
  }
  .main-slider {
    min-height: 290px;
    height: 290px;
  }
  .main-slider.new-height a figure:before {
    padding-top: 78% !important;
  }
  .main-slider a figure {
    margin-bottom: 8px;
  }
  .main-slider a figure:before {
    padding-top: 78% !important;
  }
  .buying-guide {
    margin: 0px auto 25px;
  }
  .buying-guide .decorHeading svg {
    width: 28px;
    height: 28px;
  }
  .buying-guide .guide-block {
    margin: 0 auto 20px;
    width: 100%;
  }
  .buying-guide .flex-banner {
    display: block;
  }
  .buying-guide .guide-link {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 0 0;
  }
  .category-products {
    padding: 20px 10px 10px;
    border-bottom: 2px solid #f5f5f5;
  }
  .category-products .tabs-nav {
    padding: 10px 0px;
    margin-right: -10px;
  }
  .category-products .tabs-nav li a {
    font-size: 13px;
    padding: 3px 7px;
    font-family: "pangramregular";
    margin-right: 6px;
  }
  .category-products .tabs-nav::-webkit-scrollbar {
    display: none;
  }
  .category-products .single-category {
    overflow: auto;
    white-space: nowrap;
    margin-right: -10px;
  }
  .category-products .single-category::-webkit-scrollbar {
    display: none;
  }
  .category-products .explore-btn {
    margin: 15px auto;
  }
  .category-products .explore-btn .more-btn {
    font-size: 0.9375rem;
    line-height: 18px;
    padding: 8px 10px;
  }
  .category-products .product-list {
    display: block;
  }
  .category-products .product-list .single-product {
    width: 45%;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 15px;
  }
  .category-products .product-list .single-product a {
    margin: 0;
    letter-spacing: 0;
  }
  .category-products .product-list .single-product figure:before {
    padding-top: 86%;
  }
  .category-products .product-list .single-product .name {
    font-size: 0.8125rem;
  }
  .category-products .product-list .single-product .price {
    font-size: 0.75rem;
  }
  .category-products .product-list .single-product .price .off-price,
  .category-products .product-list .single-product .price .discount-off {
    font-size: 0.6875rem;
  }
}
@media screen and (min-width: 390px) and (max-width: 415px) {
  .main-slider {
    min-height: 330px;
    height: 330px;
  }
  .newslider {
    min-height: 292px;
  }
}
@media only screen and (max-width: 320px) {
  .main-slider {
    height: 260px;
    min-height: 260px;
  }
  .newslider {
    min-height: 227px;
  }
}
.mt-0 {
  margin-top: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.mb-0 {
  margin-bottom: 0;
}
/*# sourceMappingURL=home-decor.css.map */